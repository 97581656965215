import usePlacesAutocomplete, { getGeocode } from 'use-places-autocomplete';
import { getAutocompleteAddressComponent } from '../util/geolocation';

export type AutocompletePrediction = {
  description: any;
  place_id: string;
  structured_formatting: {
    main_text: string;
    secondary_text: string;
  };
};

export const useAutoCompleteAddress = () => {
  const {
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: { country: ['us'] },
      types: ['address'],
    },
    debounce: 300,
  });

  async function getAddressComponents({ description }: AutocompletePrediction) {
    const results = await getGeocode({ address: description });
    const addressComponents = results[0].address_components;

    const selectedStreetAddress =
      getAutocompleteAddressComponent(addressComponents, 'streetAddress') || '';
    const selectedCity = getAutocompleteAddressComponent(addressComponents, 'city') || '';
    const selectedState = getAutocompleteAddressComponent(addressComponents, 'state') || '';
    const selectedZipCode = getAutocompleteAddressComponent(addressComponents, 'zipCode') || '';

    clearSuggestions();

    return { selectedStreetAddress, selectedCity, selectedState, selectedZipCode };
  }

  return { clearSuggestions, data, getAddressComponents, setValue, status, value };
};
