import React, { useEffect, useState } from 'react';
import { useUserStore } from '../../store/user';
import { SignInWith } from './SignInWith';
import { AuthMode, AuthStore, SSOProviders } from '../templates/modals/users/login/authStore';

type Props = {
  auth: AuthStore;
  className?: string;
  authModes?: Set<AuthMode>;
};

export const SignInProviders: React.FC<Props> = ({ auth, className, authModes }) => {
  const user = useUserStore();
  const [csrfToken, setCsrfToken] = useState('');

  const allSso: SSOProviders[] = Object.values(SSOProviders);
  const providers = new Set<SSOProviders>();
  (authModes || allSso).forEach((m) => {
    if (allSso.includes(m as SSOProviders)) {
      providers.add(m as SSOProviders);
    }
  });

  useEffect(() => {
    const messageHandle = setInterval(() => {
      try {
        localStorage.setItem('loginOpened', 'true');
      } catch (e) {
        console.error(e);
      }
      try {
        if (localStorage.getItem('loginComplete') === 'true') {
          localStorage.removeItem('loginComplete');
          // noinspection JSIgnoredPromiseFromCall
          auth.completedSso();
        }
      } catch (e) {
        console.error(e);
      }
    }, 500);

    async function getCsrfToken() {
      const token = await user.getCsrfToken();
      setCsrfToken(token);
    }

    // noinspection JSIgnoredPromiseFromCall
    getCsrfToken();

    return () => {
      clearInterval(messageHandle);
    };
  }, [auth, user]);

  return (
    <div className={className}>
      {providers.has(SSOProviders.Google) && (
        <SignInWith
          provider={SSOProviders.Google}
          displayName="Google"
          action="/accounts/google/login/?process=login"
          csrfToken={csrfToken}
        />
      )}
      {providers.has(SSOProviders.Microsoft) && (
        <SignInWith
          provider={SSOProviders.Microsoft}
          displayName="Microsoft"
          action="/accounts/microsoft/login/?process=login"
          csrfToken={csrfToken}
        />
      )}
    </div>
  );
};

SignInProviders.displayName = 'SignInProviders';
