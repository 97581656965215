import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';

import { Field, SelectField, TextLikeField } from '../../types/Field';
import { NameValueDict } from '../../types/FormTypes';

import { InputField } from '../atoms/InputField';
import InputError from '../atoms/InputError';
import { StandardSelectFormField } from '../atoms/StandardSelectFormField';

import styles from './AutoCompleteAddress.module.scss';
import { AddressAutocompleteSuggestions } from '../molecules/AddressAutocompleteSuggestion';
import { AutocompletePrediction, useAutoCompleteAddress } from '../../hooks/useAutocompleteAddress';

type AutoCompleteAddressProps = {
  fields: NameValueDict<Field>;
  handleChange: (field: Field, newValue: any) => boolean;
};

export const AutoCompleteAddress = observer(
  ({ fields, handleChange }: AutoCompleteAddressProps) => {
    const [selectedOption, setSelectedOption] = useState<string>('');
    const { data, getAddressComponents, value, setValue, status } = useAutoCompleteAddress();

    const handleAddressInput = (event: React.ChangeEvent<HTMLInputElement>) => {
      setValue(event.target.value);
      handleChange(fields.streetAddress, event.target.value);
    };

    function handleChangeState(event: React.ChangeEvent<HTMLSelectElement>) {
      setSelectedOption(event.target.value);
      handleChange(fields.state, event.target.value);
    }

    const handleSelect = async (suggestion: AutocompletePrediction) => {
      const { selectedStreetAddress, selectedCity, selectedState, selectedZipCode } =
        await getAddressComponents(suggestion);

      setValue(selectedStreetAddress, false);

      handleChange(fields.streetAddress, selectedStreetAddress);
      // This will not be filled by the Places API, so we
      // need to clear the current value.
      handleChange(fields.aptSuiteNumber, '');
      handleChange(fields.city, selectedCity);
      handleChange(fields.state, selectedState);
      handleChange(fields.zipCode, selectedZipCode);
    };

    return (
      <>
        <div className={classNames('field', styles.customAddressField)}>
          <label htmlFor="streetAddress">
            <span className="input-label-text">Street address</span>
          </label>
          <input
            id="streetAddress"
            type="text"
            onChange={handleAddressInput}
            value={(fields.streetAddress.value as string) || value}
          />
          {status === 'OK' && (
            <AddressAutocompleteSuggestions
              data={data}
              handleSelectedAddress={(prediction) => handleSelect(prediction)}
            />
          )}
          <InputError id={`${fields.streetAddress.id}-error`} error={fields.streetAddress.error} />
        </div>
        <InputField onChange={handleChange} field={fields.aptSuiteNumber as TextLikeField} />

        <InputField onChange={handleChange} field={fields.city as TextLikeField} />

        <div className="field">
          <StandardSelectFormField
            label={<span className="input-label-text">{fields.state.label}</span>}
            selectedOption={selectedOption}
            field={fields.state as SelectField}
            onChange={(event: React.ChangeEvent<HTMLSelectElement>) => handleChangeState(event)}
          />
        </div>

        <InputField onChange={handleChange} field={fields.zipCode as TextLikeField} />
      </>
    );
  }
);
